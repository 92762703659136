import React, { useState, useEffect } from 'react';
import css from './OrderPanel.module.css';

import EditListingAvailabilityExceptionForm from '../../containers/EditListingPage/EditListingWizard/EditListingAvailabilityPanel/EditListingAvailabilityExceptionForm/EditListingAvailabilityExceptionForm';


import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { DAY, isFullDay } from '../../transactions/transaction';
import { fetchLoadDataExceptions, getUpdateInProgress, getAllExceptions, requestFetchAvailabilityExceptions, getMontlyExceptionQuery, requestAddAvailabilityException } from '../../containers/EditListingPage/EditListingPage.duck';

import { PrimaryButton, Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import { useDispatch, useSelector } from 'react-redux';

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const defaultAvailabilityPlan = {
  type: 'availability-plan/time',
  timezone: defaultTimeZone(),
  entries: [],
};

export const EditExceptionComponent = props => {

  const {
    listing,
    onManageDisableScrolling,
    location,
    firstDayOfWeek
  } = props;

  // hooks
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const listingAttributes = listing?.attributes;
  const hasAvailabilityPlan = !!listingAttributes?.availabilityPlan;
  const availabilityPlan = listingAttributes?.availabilityPlan || defaultAvailabilityPlan;
  const unitType = listingAttributes?.publicData?.unitType;
  const dispatch = useDispatch();  
  const useFullDays = isFullDay(unitType);

  const { search } = location;

  const onFetchExceptions = params => {
    dispatch(requestFetchAvailabilityExceptions(params));
  };

  const onAddAvailabilityException = params => {
    return dispatch(requestAddAvailabilityException(params));
  };

  const exceptions = useSelector(getAllExceptions);
  const inProgress = useSelector(getUpdateInProgress);
  const monthlyExceptionQueries = useSelector(getMontlyExceptionQuery);

  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime, exceptionRange } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    const range = useFullDays
      ? {
          start: exceptionRange?.startDate,
          end: exceptionRange?.endDate,
        }
      : {
          start: timestampToDate(exceptionStartTime),
          end: timestampToDate(exceptionEndTime),
        };

    const params = {
      listingId: listing.id,
      seats,
      ...range,
    };

    return onAddAvailabilityException(params)
      .then(() => {
        setFetchError({ updateListingError: null});
        setIsEditExceptionsModalOpen(false);
      })
      .catch(error => {
        setFetchError({ updateListingError: error});
      });
  };

  useEffect(() => {
    const loadExceptions = async () => {
      try {
        if (listing && listing.id) {
          await fetchLoadDataExceptions(dispatch, listing, search, firstDayOfWeek);
        }
      } catch (error) {
        console.error('Failed to fetch exceptions:', error);
      }
    };

    loadExceptions();
  }, []);

  return (
    <>
    <PrimaryButton
      className={css.addExceptionButton}
      onClick={() => setIsEditExceptionsModalOpen(true)}
      disabled={!hasAvailabilityPlan}
    >
      <FormattedMessage id="EditListingAvailabilityPanel.addException" />
    </PrimaryButton>
    {onManageDisableScrolling && isEditExceptionsModalOpen && (
      <Modal
        id="EditAvailabilityExceptions"
        isOpen={isEditExceptionsModalOpen}
        onClose={() => setIsEditExceptionsModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        containerClassName={css.modalContainerExceptions}
        usePortal
      >
        <EditListingAvailabilityExceptionForm
          formId="EditListingAvailabilityExceptionForm2"
          listingId={listing.id}
          allExceptions={exceptions}
          monthlyExceptionQueries={monthlyExceptionQueries}
          fetchErrors={fetchError}
          onFetchExceptions={onFetchExceptions}
          onSubmit={saveException}
          timeZone={availabilityPlan.timezone}
          isDaily={unitType === DAY}
          updateInProgress={inProgress}
          useFullDays={useFullDays}
          unavailableOnly={true}
        />
      </Modal>
    )}
  </>
  )
}
